<template>
    <div>
        <nav-bar title="系统设置" url="/"></nav-bar>
        <!--用户信息-->
        <div class="top">
            <div class="title">
                <div class="title-text">用户信息</div>
                <div class="title-bg">
                    <img src="@/static/image/index/6.png">
                </div>
            </div>
            <van-cell-group>
                <van-field label-width="70" label-class="label" v-model="info.name" label="真实姓名" placeholder="请输入真实姓名">
                </van-field>
                <van-field label-width="70" label-class="label" v-model="info.sfz" label="身份证号" placeholder="请输入身份证号">
                </van-field>
                <van-field label-width="70" label-class="label" v-model="info.tel" type="number" label="手机号码"
                    placeholder="请输入手机号码"></van-field>
            </van-cell-group>
        </div>


        <!--办理须知和提交按钮-->
        <div class="action">
            <van-button type="primary" round @click="submit" color="#00afff" size="large" :color="config.main_color">
                提交设置
            </van-button>
        </div>
    </div>
</template>

<script>
    import {
        user_info,
        user_set
    } from '@/api/user.js';
    import {
        Notify,
        Dialog,
        Toast
    } from 'vant';
    export default {
        name: 'set',
        data() {
            return {
                loading: false,
                info: {},
            }
        },
        mounted() {
            this.get_data();
        },
        methods: {

            get_data() {
                user_info({
                    state: 1
                }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                    }
                });
            },

            //提交数据
            submit() {
                var that = this;
                if (!this.info.name) {
                    Toast('办理人姓名不得为空');
                    return false;
                }

                if (!this.info.sfz) {
                    Toast('办理人身份证号码不得为空');
                    return false;
                }

                if (this.info.sfz.length !== 18) {
                    Toast('办理人身份证号码长度不合法');
                    return false;
                }


                if (!this.info.tel) {
                    Toast('办理人电话号码不得为空');
                    return false;
                }

                if (this.info.tel.length !== 11) {
                    Toast('办理人电话号码长度不合法');
                    return false;
                }
                var data = {
                    sfz: this.info.sfz,
                    name: this.info.name,
                    tel: this.info.tel,
                    loading: true,
                    loading_text: '提交中……'
                };
                user_set(data).then(res => {
                    if (res.code == 1) {
                        Dialog.alert({
                            message: res.msg,
                        });
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .top {
        width: calc(100% - 40px);
        margin: 20px auto;
        background: #fff;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .title {
        background: var(--main-color);
        height: 40px;
        line-height: 40px;
        color: #fff;
        padding-left: 20px;
        position: relative;
        font-family: 'FZCS';

    }

    .title-bg {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }

    .title-bg img {
        height: 40px;
    }

    .van-button--primary {
        background-image: url('../../../static/image/index/7.png') !important;
        background-size: 100% auto !important;
        background-repeat: no-repeat;
        font-family: 'FZCS';
    }

    /deep/ .label {
        font-family: 'FZCS';
    }

    /deep/ .label,
    /deep/ .van-field__control {
        color: var(--main-color);
    }

    .action {
        margin: 20px;
        width: calc(100% - 40px);
    }

    .about {
        font-family: 'FZCS';
        color: var(--main-color);
        border: 1px solid var(--main-color);
        background: #D8F1E9;
        border-radius: 10px;
        padding: 10px;

    }

    /deep/ .van-cell__title {
        color: var(--main-color);
    }
</style>